import React from 'react';
import AdminButtons from './AdminButtons';
import CommentContext from "./CommentContext";
import NewComment from "./NewComment";

const Comment = (props) => (
  <CommentContext.Consumer>
    {({context}) => {

      // These vars toggle AdminButtons visibility
      const belongsToUser = context.user && context.user.id === props.comment.user_id;
      const canEdit = context.user && (belongsToUser || context.user.admin)

      const isEditing = props.comment.id === context.editing;

      // Link to user if they are Public
      const renderUserName = () => {
        const name = <span className="bold">{props.comment.user_name}</span>

        if(props.comment.user_public || canEdit){
          const userUrl = context.domain + `users/${props.comment.user_id}`
          return <a href={userUrl}>{name}</a>
        } else {
          return name
        }
      }


      // Comment markup
      const renderComment = () => (
        <React.Fragment>
          <div className="row align-justify">

            {renderUserName()}

            <span className="italic light dark-gray">
              {new Date(props.comment.updated_at).toLocaleDateString()}
            </span>

          </div>

          <p>{props.comment.body}</p>

          <div className="row align-justify">
            {canEdit &&
              <AdminButtons
                handleEdit={() => context.handleEdit(props.comment)}
                handleDelete={() => context.handleDelete(props.comment)}
              />
            }

            <a 
              className="underline-on-hover"
              href={`/comments/${props.comment.id}/reports/new`}
            >
              Report
            </a>
          </div>
          
        </React.Fragment>
      )


      // Form for Editing the comment
      const renderForm = () => <NewComment
        body={context.body}
        handleChange={context.handleChange}
        handleSubmit={context.handleSubmit}
        cancel={context.handleCancelEditing}
      />


      return (
        <tr>
          <td>
            {isEditing && renderForm()}
            {!isEditing && renderComment()}
          </td>
        </tr>
      )

    }}
  </CommentContext.Consumer>
);

export default Comment;
