import * as get from './get';

const HEIGHT_RATIO = 1.2;

const scaleHeight = (element, ratio) => {
  const container = element.parentElement;
  const width = container.offsetWidth;
  const height = width * HEIGHT_RATIO;
  element.style.height = height + 'px';
}

const sizePDFPreview = () => {
  const preview = document.getElementById('document-preview')
  if(preview){
    scaleHeight(preview, HEIGHT_RATIO)
    window.addEventListener('resize', () => scaleHeight(preview, HEIGHT_RATIO) );
  };
}


document.addEventListener('turbolinks:load', sizePDFPreview);
