import React from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faComment,
  faComments,
  faEnvelope,
  faTag,
  faTrash,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import getToken from '../app/getToken';
import Loading from './Loading';


const ICONS = {
  Comment: faComment,
  Conversation: faEnvelope,
  Suggestion: faTag,
}


class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded:false,
      data:[],
      error:null
    };
    this.delete = this.delete.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderDate = this.renderDate.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    // this.renderConversationURL = this.renderConversationURL.bind(this);
    this.renderOpenButton = this.renderOpenButton.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.setUrl = this.setUrl.bind(this);
  }


  componentDidMount() {
    this.fetchData();
  }


  fetchData(){
    fetch(this.props.url)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.errors });
        else this.setState({ data:res.data, loaded:true })
      })
      .catch(e => {
        this.setState({
          error: `Error loading your ${_.capitalize(this.props.type)}`,
          data: []
        })
      })
  }


  delete(id){

    // If deleteUrl present, use it, otherwise just use url prop.
    let url;
    this.props.deleteUrl ? url = this.props.deleteUrl : url = this.props.url;
    url +=  `/${id}` // append record id for deleting

    fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': getToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) {
          this.setState({
            error: `Failed to delete this ${_.capitalize(this.props.type)}`
          });
        }
        else this.fetchData();
      })
  }


  // Delete all the notifications if there are any
  deleteAll(e){
    e.preventDefault(); // stop <a> request

    if(this.state.data.length > 0){

      fetch(this.props.url, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': getToken(),
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(res => {
          if(!res.success) this.setState({ error: `Failed to delete ${_.capitalize(this.props.type)}` });
          else this.fetchData();
        })

    }
  }



  renderContent(record){
    switch(this.props.type){
      case 'notifications':
        return record.text;
        break;
      case 'conversations':
        return this.renderConversationContent(record);
        break;
      case 'suggestions':
        return record.name;
        break;
    }
  }


  // Render Name of other User & last Message
  renderConversationContent(conv){
    return <div className='full-width'>
      <header className='row align-justify'>
        <label className='nm white'>{conv.with}</label>
        <label className='cream nm small-font'>
          {this.renderDate(conv.updated_at)}
        </label>
      </header>
      <label className='nm white italic light'>{this.renderMessageSnippet(conv.last)}</label>
    </div>
  }


  // Render snippet of last message and ... if needed
  renderMessageSnippet(message){
    if(message && message.body){
      let snippet = message.body.slice(0, 25);
      if(message.body.length > 25) snippet += ' ...'
      return snippet;
    } else {
      return ' ...';
    }
  }


  renderDate(date){
    date = new Date(date);
    return date.toLocaleDateString('en-GB', {year:"2-digit",month:"2-digit", day:"2-digit"});
  }


  renderIcon(data){
    switch(this.props.type){
      case 'conversations':
       return faComments;
       break;
      case 'notifications':
        return ICONS[data.notifiable_type];
        break;
    }
  }


  renderOpenButton(data){
    switch(this.props.type){
      case 'conversations':
        return <a href={this.props.url + '/' + data.id}>
          <FontAwesomeIcon
            icon={faComments}
            className='pointer smr white'
          />
        </a>
        break;
      case 'notifications':
        return null;
        break;
    }
  }


  // URL for the Notification within the Notification list
  setUrl(data){
    switch(this.props.type){
      case 'conversations':
        return this.props.url + '/' + data.id;
        break;
      case 'notifications':
        return this.setNotificationUrl(data)
        break;
    }
  }


  // For Notifications - the Record - set what it should link to
  // REPORTS
  setNotificationUrl(data){
    switch(data.notifiable_type){
      case 'Conversation':
        return `/users/${this.props.user.id}/conversations/${data.notifiable_id}`;
        break;
      // Reports
      case 'Report':
        return `/admin/reports/${data.notifiable_id}`;
        break;
      default:
        // Comments, Favourites, Suggestions, Points
        const type = _.lowerCase(data.notifiable_type) + 's'
        return `/${type}/${data.notifiable_id}`;
        break;
    }
  }


  // For some notifications, we need to visit the link AND do something else.
  handleNotificationClick(id){
    // Delete the notification once it is clicked.
    if(this.props.type === 'notifications') this.delete(id);
  }


  render () {

    const { type } = this.props;

    const {
      loaded,
      data,
      error
    } = this.state;

    if(error) alert(error);

    if(!loaded) return <div className="row align-center">
      <Loading message={`Loading ${_.capitalize(this.props.type)}`} />
    </div>



    let list;

    if(data.length > 0){
      const sorted = _.orderBy(data, ['updated_at'], ['desc']);

      list = sorted.map((d, i) => {
        return <li
          key={type + '-' + i}
          id={type + '-' + d.id}
          className='row'
        >
          <a
            className=''
            style={{ flexGrow: 11 }}
            onClick={() => this.handleNotificationClick(d.id)}
            href={this.setUrl(d)}
          >
            <article
              className='primary-bg xspt xspb sp cream xsmb border-radius small-grow pointer shadow-hover row align-justify align-middle'
            >
              {this.renderContent(d)}
            </article>
          </a>


          {/* Notifications don't need DELETE button */}
          {type !== 'notifications' &&
            <span
              className="row align-center-middle xsml xxsmr"
            >
              {/* DELETE */}
              <FontAwesomeIcon
                onClick={() => this.delete(d.id)}
                icon={faTrash}
                className='pointer cell small-1 grow'
              />
            </span>
          }

        </li>
      })
    }


    return (
      <div
        id={'notifications-list-' + type}
        className='notifications-list'
      >

        <title
          className='flex-container align-justify align-middle smb'
        >
          <h4 className='nm'>
            {type === 'conversations' ? 'Messages' : _.capitalize(type)}
          </h4>

          {/*  CLEAR ALL - for Notifications, if any */}
          {type === 'notifications' && list &&
            <a
              href='#'
              onClick={this.deleteAll}
              className='italic text-shadow mmr'
            >
              CLEAR
            </a>
          }
        </title>


        {/*  LIST  */}
        <section
          className='callout cream-bg border-radius xsp spl'
        >
          {list &&
            <React.Fragment>

              <ul className='no-bullet-list nm inner-notifications-list'>
                {list}
              </ul>

              {/*  COUNT - for Notifications only */}
              {type === 'notifications' &&
                <p
                  id='notifications-count'
                  className='absolute top right lead alert-bg sp circle-radius bold cream mmt-minus mmr-minus flex-container align-center-middle shadow'
                >
                  {list.length}
                </p>
              }

            </React.Fragment>
          }

          {!list &&
            <p className='italic text-center gray mp'>
              {'No ' +  _.capitalize(this.props.type)}
            </p>
          }

        </section>

      </div>
    );
  }
}


NotificationList.propTypes = {
  // The type of Notification, e.g 'notifications', 'conversations', etc
  type: PropTypes.string,

  // URL to apply in fetch request, e.g user_conversations_url(@user)
  url: PropTypes.string.isRequired,

  // User as an Object, i.e as_json
  user: PropTypes.object,

   // If required, a URL for DELETE requests.
   // Most records can use the url prop, but some require separate url
  deleteUrl: PropTypes.string,
};

export default NotificationList;
