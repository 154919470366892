const jiggle = function(){
  // find all elements with class 'post-it'
  var elements = document.getElementsByClassName('jiggle')


  // for each post it
  for (var i = 0; i < elements.length; i++){
    jiggleElement(elements[i]);
  }
}

export const jiggleElement = (el) => {
  let range = 10; // amount of jiggling (-7 to -7)

  // reduce range for mild-jiggle elements
  if(el.classList.contains('mild-jiggle')) range = range * 0.25;

  // generate a degree
  var rand = Math.floor((Math.random() * range) - (range * 0.5));

  // insert a rotate of degree into post-it style
  el.style.transform = el.style.transform + " rotate(" + rand + "deg)";

  // don't proceed to add margin for mild-jigglers
  if(!el.classList.contains('mild-jiggle')) {
    el.style.marginTop = Math.floor(rand * 0.2) + "px";
    el.style.marginRight = Math.floor(rand * 0.2) + "px";
    el.style.marginLeft = Math.floor(rand * 0.2) + "px";
  }
}

document.addEventListener('turbolinks:load', jiggle);
