import * as vis from './visibility';

const setupMultipleChoiceListeners = () => {
  const containers = document.querySelectorAll('.multiple-choice-checkboxes');
  for(let i = 0; i < containers.length; i++){
    const container = containers[i];
    const cb = container.querySelector('input[type="checkbox"]');
    cb.addEventListener('change', toggleOptionsFields)
  }
}

document.addEventListener('turbolinks:load', function(e) {
  setupMultipleChoiceListeners()
  $(document).on('cocoon:after-insert', setupMultipleChoiceListeners)
});


const toggleOptionsFields = (e) => {
  const optionField = e.target.parentElement.parentElement.nextElementSibling;
  if(optionField.id == 'option-field'){
    e.target.checked ? vis.show(optionField) : vis.hide(optionField);
  }
}
