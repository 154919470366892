function setDataIndex() {
  // get lists of  file inputs & .valid-message & .invalid-message elements
  // all those lists have the same length, so we can use same index
  // to update data-index attribute
  const fileInputs = document.querySelectorAll('#media-fields [data-index]');
  const validMessages = document.querySelectorAll('.valid-message');
  const invalidMessages = document.querySelectorAll('.invalid-message');

  // During the same event, auto set the index field value
  const setIndexField = document.querySelectorAll('input.set-index-field');

  for (let index = 0; index < fileInputs.length; index++) {
    fileInputs[index].setAttribute('data-index', index)
    validMessages[index].setAttribute('data-index', index)
    invalidMessages[index].setAttribute('data-index', index)
    if(shouldSetIndexField(setIndexField[index])) {
      setIndexField[index].value = nextAvailableIndex();
    }
  }
}

// Set the index field value automatically if:
// - it exists
// - it is empty
// - it belongs to a newly created object
const shouldSetIndexField = (field) => {
  return field && !field.value && field.getAttribute('data-new');
}

const nextAvailableIndex = () => {
  const setIndexFields = document.querySelectorAll('input.set-index-field');
  if(setIndexFields.length === 1) return 1;
  else {
    const values = Array.from(setIndexFields).map((field) => parseInt(field.value));
    // remove all NaN values
    const filteredValues = values.filter((value) => !isNaN(value));
    return Math.max(...filteredValues) + 1;
  }
}

document.addEventListener('turbolinks:load', setDataIndex);
document.addEventListener('ajax:success', setDataIndex)

// Note - can i refactor this, will Jquery allow?
$(document).on('cocoon:after-insert', function(e) {
  setDataIndex()
});