const parseForInvalidCharacters = (string) => {
  let parsed = string.replaceAll(':', '-');
  parsed = parsed.replaceAll(';', '-');
  parsed = parsed.replaceAll("'", '-');
  parsed = parsed.replaceAll(",", '-');
  parsed = parsed.replaceAll(".", '-');
  parsed = parsed.replaceAll("/", '-');
  parsed = parsed.replaceAll("*", '-');
  parsed = parsed.replaceAll("&", '-');
  parsed = parsed.replaceAll(" ", '-');
  parsed = parsed.replaceAll("!", '-');
  parsed = parsed.replaceAll("¡", '-');
  return parsed
}

export default parseForInvalidCharacters;
