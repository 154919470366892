import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons"
import _ from 'lodash';

const Loading = (props) => (
    <span className={props.class}>
      <FontAwesomeIcon icon={faCog} className={`fa-spin mmr ${props.iconClass}`}/>
      {props.message}
    </span>
)

export default Loading;

Loading.defaultProps = {
  message: 'Loading...'
}
