import * as vis from "./visibility";

export class SculptureCarousel {
  constructor(itemsClassName) {
    this.carousel = document.querySelectorAll(itemsClassName);
    this.position = 0;
    this.slides = this.carousel.length;
    this.closeButton = document.querySelector("#close-button");
    this.next = document.querySelector("#right-arrow");
    this.prev = document.querySelector("#left-arrow");
    console.log(this.carousel);
    if (this.carousel && this.slides > 1) {
      console.log('here');
      for (let i = 0; i < this.slides; i++) {
        if (i == 0) {
          this.carousel[i].classList.remove("hidden");
        } else {
          this.carousel[i].classList.add("hidden");
        }
      }
    }
    this.hideOrShowArrows();
    this.addEventListeners();
  }
  init(itemsClassName){
    new SculptureCarousel(itemsClassName);
  }

  hideOrShowArrows() {
    if (this.slides <= 1) {
      vis.hideById("left-arrow");
      vis.hideById("right-arrow");
    } else {
      vis.showById("left-arrow");
      vis.showById("right-arrow");
    }
  }
  addEventListeners() {
    this.closeButton.addEventListener("click", this.handleCloseModal);

    if (this.next) {
      this.next.addEventListener("click", () => {
        this.incrementPossition();
      });
    }

    if (this.prev) {
      this.prev.addEventListener("click", () => {
        this.decrementPossition();
      });
    }
  }
  updateSlide() {
    for (let i = 0; i < this.carousel.length; i++) {
      this.carousel[i].classList.add("hidden");
    }
    this.carousel[this.position].classList.remove("hidden");
  }

  incrementPossition() {
    if (this.position === this.slides - 1) {
      this.position = 0;
    } else {
      this.position++;
    }
    this.updateSlide();
  }

  decrementPossition() {
    if (this.position === 0) {
      this.position = this.slides - 1;
    } else {
      this.position--;
    }
    this.updateSlide();
  }

  handleCloseModal = () => {
    const scrollY = document.body.style.top;

    /* 
      Code copied from: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
      in order to prevent the body from scrolling when the modal is open
      here we simple revert the changes made in 
      hadnleTabClick
    */
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.querySelector("#carousel").innerHTML = "";
    vis.hideById("story-modal");
  };
}

