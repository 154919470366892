import Amplitude from 'amplitudejs';

document.addEventListener('turbolinks:load', () => {
  const audioPlayer = document.querySelector('#amplitude-audio-player');
  if(audioPlayer){
    let url = audioPlayer.getAttribute('data-url');
    // Get the item data, if there are any audios for the item, load Amplitude
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const { audios } = data;
        if(audios) initalizeAmplitude(data);
      })
  }
})


const initalizeAmplitude = (data) => {
  const songs = [];
  for(let i = 0; i < data.audios.length; i++){
    const audio = data.audios[i];
    songs.push({
      index: i,
      name: audio.name ? audio.name : data.name,
      artist: data.name,
      url: audio.src,
      cover_art_url: data.images.length > 0 ? data.images[0].src : null
    });
  }
  Amplitude.init({ songs: songs });
}