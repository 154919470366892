// Shift the add button to after the newly created field
document.addEventListener('turbolinks:load', function(e) {
  $(document).on('cocoon:after-insert', function(e) {
    const button = e.currentTarget.activeElement;
    if(!button.classList.contains('do-not-shift')) {
      const container = button.parentElement
      const newFields = container.nextElementSibling;
      container.appendChild(newFields);
      container.appendChild(button);
    }
  })
});
