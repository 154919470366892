import * as vis from './visibility';

document.addEventListener('turbolinks:load', () => {
  const copyrightFields = document.getElementById('copyright-fields');
  const copyrightRadio = document.getElementById('item_download_false');
  const publicRadio = document.getElementById('item_download_true');

  if(copyrightFields && copyrightRadio && publicRadio){

    // show copyright fields if copyright checked on load
    if(copyrightRadio.checked){
      vis.show(copyrightFields);
      copyrightRadio.required = true;
    } else {
      vis.hide(copyrightFields);
      copyrightRadio.required = false;
    }

    // Show copyright fields when copyright changes to checked
    copyrightRadio.addEventListener('change', (e) => {
      if(e.target.checked) vis.show(copyrightFields);
      copyrightRadio.required = true;
    })

    // Hide copyright fields when public domain is checked
    publicRadio.addEventListener('change', (e) => {
      if(e.target.checked){
        vis.hide(copyrightFields);
        copyrightRadio.required = false;
        copyrightRadio.value = ''; // clear copyright attribution
      }
    })
  }
});
