import React from "react";
import PropTypes from "prop-types";

class FreeTextQuestion extends React.Component {
  render () {
    const {
      answer,
      handleChange,
    } = this.props;

    return (
      <div className="new-input-container">
        <input
          type="text"
          className='lf-input'
          value={answer}
          onChange={handleChange}
          placeholder={'Type Answer here...'}
        />
      </div>
    );
  }
}

FreeTextQuestion.propTypes = {
  handleChange: PropTypes.func,
  answer: PropTypes.string,
};

export default FreeTextQuestion
