import React from "react";
import PropTypes from "prop-types";
import { areaFactors } from "@turf/helpers";

const ItemPreview = (props) => {

  const { item } = props;

  const renderItemMedia = () => {
    const images = item.images.map((img, i) => {
      return <img src={img.src} alt={img.name ? img.name : item.name}/>
    });
    const audios = item.audios.map((a, i) => {
      return <audio src={a.src} controls />
    });
    const videos = item.videos.map((v, i) => {
      return <video src={v.src} controls />
    });
    const docs = item.documents.map((doc, i) => {
      return <p>
        <strong>{doc.name}</strong>
        <span className='normal-weight italic'> (Preview Unavailable)</span>
      </p>
    });
    // iterate through images, returning array of images
    return <div id='media-previews'>
      {images}
      {audios}
      {videos}
      {docs}
    </div>
  }

  const renderMediaTypes = () => {
    const types = item.media_types.map((type, i) => {
      return <div className='tag'>
        <i className="fa fa-tag" aria-hidden="true"></i>
        {type}
      </div>
    })
    if(types.length > 0) {
      return <div className='mmb'>
        <p className='smb'>This Item includes:</p>
        {types}
      </div>
    }
  }

  // DEPRECATED
  // const renderItemMedia_DEP = () => {
  //   switch(item.media_type){
  //     case 'Image':
  //       return <img src={item.image} alt={item.name}/>
  //       break;
  //     case 'Audio':
  //       return <audio src={item.audio} controls />
  //       break;
  //     case 'Video':
  //       return <video src={item.video} controls />
  //       break;
  //     case 'Document':
  //       return <p
  //         className="red mp italic dark-gray-border"
  //       >
  //         Sorry, but Document's can't be used in Points right now.
  //       </p>
  //       break;
  //   }
  // }

  return (
    <React.Fragment>
      <p className='italic xsmb std-font dark-gray'>Archive Item</p>

      <div
        className="callout cream-bg border-radius mp smb"
      >
        {item.name &&
          <h5 className="smb">
            {item.name}
          </h5>
        }

        {item.description &&
          <p className="mmb">
            {item.description}
          </p>
        }

        {/* {renderItemMedia()} */}
        {renderMediaTypes()}

        <a href={`/items/${item.id}`} target='_blank' className='button'>
          <i className="fa fa-external-link-square xsmr" aria-hidden="true"></i>
          View Item
        </a>
      </div>
    </React.Fragment>
  );
}

export default ItemPreview;
