// REMOVE Element
// To use:
//  - add 'remover' class to element which should trigger removal
//  - set trigger elements data-remove attribute with ID of element to remove


const removeElement = (e) => {
  if(e.target.classList.contains('remover')){
    const toRemove = document.getElementById(e.target.dataset.remove);
    toRemove.outerHTML = '';
  }
}

document.addEventListener('click', removeElement);
