import bodyClass from './bodyClass';
import Rails from '@rails/ujs';

const setAddGameListener = () => {
  const gameable = bodyClass('points');
  if(gameable && (bodyClass('new') || bodyClass('edit'))){
    const addButtons = document.querySelectorAll("[id*='add-']");
    for(let i = 0; i < addButtons.length; i++){
      addButtons[i].addEventListener('click', setTypeAndSubmitForm)
    }
  }
}

const setTypeAndSubmitForm = (e) => {
  e.preventDefault();
  const type = e.target.getAttribute('data-add');
  const form = document.querySelector('form');
  // add a redirect to the form
  const redirectField = document.createElement('INPUT');
  redirectField.type = 'hidden';
  redirectField.name = 'redirect_to';
  redirectField.value = type;
  form.appendChild(redirectField);
  Rails.fire(form, 'submit');
}

document.addEventListener('turbolinks:load', setAddGameListener);
document.addEventListener('ajax:complete', setAddGameListener);