import React from "react"
import PropTypes from "prop-types"
const NewMessage = (props) => {
  return (
    <form
      id="new-message"
      onSubmit={props.handleSubmit}
    >
      <label
        htmlFor="message_body"
        className='lead italic bold'
      >
        Send a New Message
      </label>

      <input
        type="text"
        name="body"
        id="message_body"
        value={props.body}
        onChange={props.handleChange}
        // className='xsmb'
        placeholder='Type your message here ....'
      />

      <input
        className='button expanded'
        // style={{ fontSize:'1.2rem' }}
        type="submit"
        value="Send"
      />
    </form>
  );
}

export default NewMessage
