export const elementsWhereIdIncludes = (string) => {
  const elements = document.querySelectorAll("[id]");
  for (let i = 0; i < elements.length; i++){
    const el = elements[i];
    if(el.id.includes(string)) return el;
  }
}

export const byId = (data, id) => {
  let found;
  data.forEach(obj => {
    if(obj.id === id) found = obj;
  })
  if(!found) {
    data.forEach(obj => {
      if(obj.properties.id === id) found = obj;
    })
  }
  return found;
}

export const getId = (object) => {
  const id = document.getElementById(`${object}-id`);
  const key = `${object}Id`;
  const string = id.dataset[key];
  return parseInt(string);
}
