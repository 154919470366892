// written by Stephen Murdoch
// taken from Link Up West Dunbartonshire

var triggerFileAttachedEvent = function(target) {
  triggerCustomEvent(target, "direct-uploads:file-attached", "A file was attached to a file input");
}

var triggerCustomEvent = function(target, name, message) {
  var e = new CustomEvent(name,
    {
      detail: {
        message: message,
        file: target.files[0]
      },
      bubbles: true,
      cancelable: true
    });

  target.dispatchEvent(e);
}

document.addEventListener('change', function(e) {
  var target = e.target;

  if(target && target.tagName == 'INPUT' && target.type == 'file' && target.dataset.directUploadUrl) {
    triggerFileAttachedEvent(target);
  }
});
