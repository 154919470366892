import React from "react"
import PropTypes from "prop-types"
class Errors extends React.Component {
  render () {
    if(this.props.errors){
      console.log('Errors: ' + this.props.errors);

      return <p className='dark-red bold h5'>
        Oh no! Somethings gone wrong. Please try again.
      </p>

    } else return null
  }
}

Errors.propTypes = {
  errors: PropTypes.object
};
export default Errors
