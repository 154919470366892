import React from 'react';
import parseForInvalidCharacters from './parseForInvalidCharacters';
import { normalize } from '../app/normalize';

const OptionButton = (props) => {
  const parsedId = parseForInvalidCharacters(props.id);
  const normalizedId = normalize(parsedId, false)
  return (
    <button
      className={`button option-button cell mmb ${props.answer == props.option ? 'gray-bg' : 'primary-bg'}`}
      onClick={() => props.handleClick(props.option)}
      id={normalizedId}
    >
      {props.option}
    </button>
  );
}

export default OptionButton;
