let loaded;

const square = () => {
  const records = document.querySelectorAll('.square')
  for(let i = 0; i < records.length; i++){
    const record = records[i];
    // set record height only if record has computed width i.e image has loaded
    record.style.height = record.offsetWidth !== 0 && record.offsetWidth + 'px';
  }

  // Use loaded variable to determine whether to set listener
  // to avoid the listener calling itself again.
  if(!loaded) setResizeListener();
  loaded = true;
}

// resize record height on window resize
const setResizeListener = () => {
  window.addEventListener('resize', () => {
    square();
  })
}

document.addEventListener('turbolinks:load', () => {
  loaded = false;
  square()
})

document.addEventListener('click', (e) => {
  if(e.target.classList.contains('trigger-square-on-click')) square();
})

export default square;


