let index = 0;

// add event listener son turbolinks:load
const mySlideshow = () => {
  // find mySlideshow
  const mySlideshow = document.querySelector(".my-slideshow");
  index = 0;
  if(mySlideshow){
    // get the slides inside it
    const slides = mySlideshow.getElementsByClassName("my-slide");
    // set the slides[index] to active
    slides[index].classList.add("active");
    // set the slide-buttons[index] to active
    const slideButtons = mySlideshow.parentElement.getElementsByClassName("slide-button");
    if(slideButtons.length > 0){
      slideButtons[index].classList.add("active"); 

      // add event listener setSlide to each button on click
      for (let i = 0; i < slideButtons.length; i++) {
        slideButtons[i].addEventListener("click", () => {
          setSlide(i);
        });
      }
    }

    const setSlide = (i) => {
      // remove active from all slides
      for (let j = 0; j < slides.length; j++) {
        slides[j].classList.remove("active");
      }
      // remove active from all slide-buttons
      for (let j = 0; j < slideButtons.length; j++) {
        slideButtons[j].classList.remove("active");
      }
      // set the slides[i] to active
      slides[i].classList.add("active");
      // set the slide-buttons[i] to active
      slideButtons[i].classList.add("active");
      // set index to i
      index = i;
      // scroll to top of mySlideshow.parentElement
      mySlideshow.parentElement.scrollTo({behavior: "smooth", top: 0, left: 0});
    }
  }
}

export default mySlideshow;