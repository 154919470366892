// If we are adding any Audios via a nested form, then we need to show
// the audio index field to allow the user to set where it appears in the
// slideshow. We need to toggle the visibility of the audio index field
// based upon the number of audio fields that are visible as they are added
// and removed from the form.

$(document).on('cocoon:after-insert', () => toggleAudioIndexField());
$(document).on('cocoon:after-remove', () => toggleAudioIndexField());

const toggleAudioIndexField = () => {
  // get fields where name includes [audios_attributes] and type is file
  const audioFields = document.querySelectorAll('[name*="audios_attributes"][type="file"]');
  // Remove any audioFields that are being deleted
  // for loop over audioFields
  for (let index = 0; index < audioFields.length; index++) {
    // where closest parent with .nested-fields has style display: none
    if(audioFields[index].closest('.nested-fields').style.display === 'none') {
      // remove this field from audioFields
      audioFields[index].remove();
    }
  }

  // if there are any audioFields then show the audio index field
  // otherwise we want to hide it
  const audioIndexField = document.querySelector('#audio-index-field');
  if(audioIndexField) {
    if(audioFields.length > 0){
      audioIndexField.classList.remove('hidden');
    } else {
      audioIndexField.classList.add('hidden');
    }
  }
}