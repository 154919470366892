const JsRoutesRails = (function() {
  var routes = {};

  routes['contact_path'] = function(options) {
    return format('/contact', options);
  };

  routes['sense_of_place_toolkit_ardrossan_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/ardrossan', options);
  };

  routes['sense_of_place_toolkit_barrmill_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/barrmill', options);
  };

  routes['sense_of_place_toolkit_beith_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/beith', options);
  };

  routes['sense_of_place_toolkit_dalry_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/dalry', options);
  };

  routes['sense_of_place_toolkit_irvine_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/irvine', options);
  };

  routes['sense_of_place_toolkit_kilbirnie_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/kilbirnie-glengarnock', options);
  };

  routes['sense_of_place_toolkit_kilwinning_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/kilwinning-dalgarven', options);
  };

  routes['sense_of_place_toolkit_lochwinnoch_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/lochwinnoch', options);
  };

  routes['sense_of_place_toolkit_saltcoats_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/saltcoats', options);
  };

  routes['sense_of_place_toolkit_stevenston_path'] = function(options) {
    return format('/sense-of-place-toolkit/places/stevenston', options);
  };

  routes['trail_reports_path'] = function(options) {
    return format('/trails/:trail_id/reports', options);
  };

  routes['new_trail_report_path'] = function(options) {
    return format('/trails/:trail_id/reports/new', options);
  };

  routes['trail_comments_path'] = function(options) {
    return format('/trails/:trail_id/comments', options);
  };

  routes['trail_comment_path'] = function(options) {
    return format('/trails/:trail_id/comments/:id', options);
  };

  routes['trail_points_path'] = function(options) {
    return format('/trails/:trail_id/points', options);
  };

  routes['new_trail_point_path'] = function(options) {
    return format('/trails/:trail_id/points/new', options);
  };

  routes['edit_point_path'] = function(options) {
    return format('/points/:id/edit', options);
  };

  routes['point_path'] = function(options) {
    return format('/points/:id', options);
  };

  routes['instructions_trails_path'] = function(options) {
    return format('/trails/instructions', options);
  };

  routes['trails_path'] = function(options) {
    return format('/trails', options);
  };

  routes['new_trail_path'] = function(options) {
    return format('/trails/new', options);
  };

  routes['edit_trail_path'] = function(options) {
    return format('/trails/:id/edit', options);
  };

  routes['trail_path'] = function(options) {
    return format('/trails/:id', options);
  };

  routes['archive_path'] = function(options) {
    return format('/archive', options);
  };


  function format(string, options) {
    var str = string.toString();
    for (var option in options) {
      str = str.replace(RegExp("\\:" + option, "gi"), options[option]);
    }

    return str;
  };

  return routes;
})();


const SopRoutes = {
  "Ardrossan":    JsRoutesRails.sense_of_place_toolkit_ardrossan_path(),
  "Barrmill":     JsRoutesRails.sense_of_place_toolkit_barrmill_path(),
  "Beith":        JsRoutesRails.sense_of_place_toolkit_beith_path(),
  "Dalry":        JsRoutesRails.sense_of_place_toolkit_dalry_path(),
  "Irvine":       JsRoutesRails.sense_of_place_toolkit_irvine_path(),
  "Kilbirnie":    JsRoutesRails.sense_of_place_toolkit_kilbirnie_path(),
  "Kilwinning":   JsRoutesRails.sense_of_place_toolkit_kilwinning_path(),
  "Lochwinnoch":  JsRoutesRails.sense_of_place_toolkit_lochwinnoch_path(),
  "Saltcoats":    JsRoutesRails.sense_of_place_toolkit_saltcoats_path(),
  "Stevenston":   JsRoutesRails.sense_of_place_toolkit_stevenston_path(),
  "Glengarnock":  JsRoutesRails.sense_of_place_toolkit_kilbirnie_path(),
  "Dalgarven":    JsRoutesRails.sense_of_place_toolkit_kilwinning_path(),
}

export { JsRoutesRails, SopRoutes };