import React from "react"
import PropTypes from "prop-types"
const Message = (props) => {

  const { message, user } = props;

  const date = new Date(message.updated_at).toLocaleDateString()

  const myMessage = message.user == user.name;

  return (
    <li
      className={`${myMessage ? 'white-bg primary-border' : 'primary-bg white-border shadow'} smb mp`}
      id={'message-' + message.id}
      style={{
        width: '70%',
        borderRadius: '1.5rem',
        alignSelf: `${myMessage ? 'flex-end' : 'flex-start'}`
      }}
    >
      <div
        className={`row mmb align-justify`}
      >
        <label className={`${myMessage ? 'dark-gray' : 'white'}`}>
            {myMessage ? 'Me' : message.user}
        </label>

        <label className={`${myMessage ? 'dark-gray' : 'white'} italic light`}>{date}</label>
      </div>

      <p
        className={`smb ${myMessage ? 'text-right black' : 'text-left white'}`}
      >
        {message.body}
      </p>

      {!myMessage &&
        <footer
          className='row align-right white'
        >
          <a
            className="small-font italic white"
            href={`/messages/${message.id}/reports/new`}
          >
            Report
          </a>
        </footer>
      }

    </li>
  );
}

export default Message
