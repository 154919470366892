export const red = "#B86C5B";
export const blue = "#124A6C";
export const lightestBlue = "#1e68fa";
export const lightBlue = "#3778fb";
export const medblue = '#486C80';
export const lightMedBlue = "#51768F";
export const blueTrans = "rgba(18, 74, 108, 0.9)"
export const green = "#85AC58";
export const darkGreen = "#4D7123";
export const orange = "#DE6F06";
export const cream = "#EDEDE3";
export const paper = "#E5DDC1";
export const lightPaper = "#C8C8C9";
export const transparent = "rgba(0, 0, 0, 0)";
export const pink = '#E95576';
export const lightPink = '#f08fa4';
