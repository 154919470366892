import React from "react";
import PropTypes from "prop-types";
import ItemList from "./ItemList";
import ItemPreview from "./ItemPreview";
import FilterRecords from "./FilterRecords";
import Loading from "./Loading";
import getToken from '../app/getToken';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import * as vis from '../app/visibility';
import * as get from '../app/get';
import _ from 'lodash';
import Rails from '@rails/ujs';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}


class PointOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingItems: false,
      removingItem: false,
      error: null,
      items: [],
      showingForm: false,
      showingItems: false,
      showingFilter: false,
      submitting: false,
      note: '',
      category_id: '',
      locality_id: '',
      // media_type: '', // DEPRECATED
      search: '',
      categories: [],
      localities: [],
    };
    this.addMyOwn = this.addMyOwn.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.loadItems = this.loadItems.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearItemAndEnableForm = this.clearItemAndEnableForm.bind(this);
    this.handleChooseFromArchive = this.handleChooseFromArchive.bind(this);
  }

  componentDidMount() {
    const { point } = this.props;
    // Point#New
    if(!point) this.setState({loading:false})
    // Point#Edit
    else {
      if(point.item_id) this.loadItem(point.item_id);
      else this.addMyOwn();
    }
  }


  // Hide some elements to make space - all with .hidden-when-adding-info class
  // Mainly intended for small screen.
  hideElements(){
    const elements = document.querySelectorAll('.hidden-when-adding-info');
    elements.forEach(el => vis.hide(el));
  }


  // If there is an Item already, confirm user happy to clear it,
  // then, update Point to remove Item and refresh page
  // This prevents issue about updating the media and deleting the old one
  addMyOwn(){
    this.hideElements();

    if(this.props.point && this.props.point.item_id){
      if(confirm('Are you happy to remove this Item from the Point and write your own? This will remove the Item and refresh this page.')) this.removeItem()
    } else {
      this.clearItemAndEnableForm()
    }
  }


  removeItem(){
    this.setState({loading:true, removingItems:true})

    fetch(`${this.props.point_url}`, {
      method: `PATCH`,
      headers: HEADERS,
      body: JSON.stringify({ point: { item_id: null } })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error:e })
        else window.location.reload(); // refresh page to get updated Point
      })
      .catch(e => this.setState({ error:e }))
  }


  // Clear Item id value and show Form
  clearItemAndEnableForm(){
    this.clearItemFields()
    this.setState({
      item:null,
      showingItems:false,
      note:'',
      loading:false
    }, this.toggleForm);
  }


  clearItemFields(){
    const idField = get.elementsWhereIdIncludes('_item_id');
    if(idField) idField.value = null;
    const noteField = get.elementsWhereIdIncludes('_note_for_item_point');
    if(noteField) noteField.value = '';
  }


  toggleForm(){
    vis.toggleById('form');
    this.toggleQuizButtons();
    this.setState({ showingForm:!this.state.showingForm })
  }

  toggleQuizButtons(){ 
    // // either Add or Edit Quiz button will be there
    // vis.toggleById('add-quiz');
    // vis.toggleById('edit-quiz');
  }


  handleChooseFromArchive(){
    this.hideElements();
    this.loadItems();
  }


  loadItems(){
    this.setState({loadingItems:true})

    vis.hideById('form'); // ensure form is hidden

    fetch(`${this.props.url}.json/${this.setFilterParams()}`)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: 'Error loading Items' })
        else {
          this.setState({
            items: res.items,
            categories: res.categories,
            localities: res.localities,
            showingItems: true,
            loadingItems: false,
            showingForm: false,
            error: null
          })
        }
      })
  }


  loadItem(id){
    fetch(`${this.props.url}/${id}.json`)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: 'Error loading Items' })
        else {
          this.setState({
            item: res.item,
            loading: false,
            error: null
          })
        }
      })
  }


  // Add the Item to State and the Form
  handleItemSelect(id){
    const idField = get.elementsWhereIdIncludes('_item_id');
    if(idField) {
      idField.value = id; // set item in form
      // Get item
      const item = _.find(this.state.items, { id: id })
      this.setState({ showingItems:false, item:item }, this.toggleQuizButtons)
    } else {
      this.setState({
        error: 'There was a problem choosing this Item. Try again'
      })
    }
  }


  changeItem(){
    this.setState({ showingItems:true, item:null }, this.loadItems)
  }


  handleNoteChange(e){
    const noteField = get.elementsWhereIdIncludes('_note_for_item_point');
    if(noteField) noteField.value = e.target.value;
    this.setState({ note: e.target.value });
  }

  // CAN I COMBINE ^ and below ???

  handleChange(e){
    const newState = this.state;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  handleSubmit(e){
    e.preventDefault();
    this.loadItems()
  }


  setFilterParams(){
    const { category_id, locality_id, search } = this.state;
    return `?category_id=${category_id};locality_id=${locality_id};search_query=${search}`
  }


  // Get the Point form and submit as normal
  handleSave(){
    const form = document.getElementsByTagName('FORM')[0];
    // We have to use Rails to submit the form in order to use
    // the remote AJAX behaviour. Using form.submit() vanilla JS will
    // trigger full HTML request.
    Rails.fire(form, 'submit');
  }


  render () {

    const {
      loading,
      showingForm,
      showingItems,
      loadingItems,
      items,
      item,
      note,
      submitting,
      category_id,
      locality_id,
      categories,
      localities,
      showingFilter
    } = this.state;

    const filterValues = {
      category_id: category_id,
      locality_id: locality_id,
    }

    if(loading) return (
      <div className="mmb">
        <Loading
          message={this.state.removingItems ? 'Removing Item...' : 'Loading...'}
        />
      </div>
    )


    return (
      <React.Fragment>
        {/* Buttons */}
        <div className="row mmb align-center">
          <button
            onClick={this.addMyOwn}
            className="button xsm"
            disabled={showingForm}
          >
            Add My Own
          </button>

          <button
            onClick={this.handleChooseFromArchive}
            className="button xsm"
            disabled={showingItems || item}
          >
            Choose From Archive
          </button>
        </div>


        {loadingItems &&
          <div className="mmb">
            <Loading message='Loading Items'/>
          </div>
        }


        {showingItems &&
          <React.Fragment>

            <h4>Choose an Item</h4>

            <p className="text-center italic smb std-font">
              Click VIEW to preview it in a new tab.
            </p>

            <div className="row align-justify align-middle xspl">

              <p className="text-center italic smb std-font bold">
                Showing {items.length} Items
              </p>

              <button
                className="button smb pointer"
                onClick={()=>this.setState({showingFilter:!showingFilter})}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  className='cream smr'
                />
                {showingFilter ? 'Hide Filter' : 'Filter'}
              </button>
            </div>


            <FilterRecords
              showingFilter={showingFilter}
              values={filterValues}
              categories={categories}
              localities={localities}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            />

            <ItemList
              url={this.props.url}
              items={items}
              handleItemSelect={this.handleItemSelect}
            />
          </React.Fragment>
        }

        {item &&

          <React.Fragment>

            <ItemPreview item={item} />

            <div className="callout primary-bg mp white">
              <h5 className="smb white">Add a Note?</h5>

              <p className="italic std-font">
                You might want to add your own note related to it's position on the Trail.'
              </p>

              <input
                className='xsmb'
                type="text"
                name="note"
                onChange={this.handleNoteChange}
                value={note}
                placeholder="i.e 'Look left and you will you see ...''"
              />
            </div>

            <button
              onClick={this.handleSave}
              className="button xsmb expanded"
              disabled={submitting}
            >
              {`${submitting ? 'Saving....' : 'Save'}`}
            </button>

            <button
              onClick={this.changeItem}
              className="button xsmb expanded"
            >
              Change Item
            </button>

          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default PointOptions;
