var displayEmail = function(){
  var one = 'info@ic';
  var two = 'ecream';
  var three = 'archite';
  var four = 'cture';
  var five = '.com';

  var emailDivs = document.getElementsByClassName('contact-email');

  for (var i = 0; i < emailDivs.length; i++){
    emailDivs[i].innerHTML = "<a href='" + "mail" + "to:" + one + two + three + four + five + "'>" + one + two + three + four + five + "</a>";
  }
}

document.addEventListener('turbolinks:load', displayEmail);
