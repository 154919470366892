import * as mapData from "./mapData";
import {SculptureCarousel} from "./storiesSlider";
import * as vis from "./visibility";
let map;
let mapContainer;

const addWatedropImage = () => {
  map.loadImage(
    "https://make-your-way.s3.eu-west-2.amazonaws.com/waterdrop-png.png",
    (error, image) => {
      if (error) throw error;

      // Add the image to the map style.
      map.addImage("waterdrop", image);
    }
  );
};

const setupSlider = () => {
 new SculptureCarousel(".carousel-slide");
};




const setupSculptureMap = () => {
  // dont't do anything if on mobile
  
  mapContainer = document.getElementById("sculptureMap");

  if (mapContainer) {
    map = mapData.setupMap("sculptureMap");
    if (!mapContainer.classList.contains("no-ctrl"))
      mapData.addControls(map, "top-right");
    map.on("load", () => {
      addWatedropImage();
      mapData.addSculpturesLayer(map, "sculptures.json");
  
      map.on("click", "sculptures-points", (e) => {
        const properties = e.features[0].properties;
        let stories = JSON.parse(properties.stories);

        stories.forEach((story) => {
         populateModal(story);
        });
        setupSlider();
        vis.showById("story-modal");
      });
    });
  }
};

const populateModal = (story) => {
  let carousel = document.querySelector("#carousel");
  let storyWrapper = document.createElement("div");
  storyWrapper.classList = "carousel-slide fade-in";


  storyWrapper.innerHTML =  `
  <div class="header">
    <img class="modal-img" loading="lazy" src="${story.image}" alt="${story.title}">
    <div class="title-wrapper">
      <p>${story.name}</p>
    </div>
  </div>
  <div class="content">
    <p class="description">${story.description}</p>
  </div>
  `;
  carousel.appendChild(storyWrapper);

}

const hadnleTabClick = () => {
  let tabs = document.querySelectorAll(".tab-container");
  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      let story = tab.getAttribute("data-story");

      /**
       * Copied from https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
       * in order to prevent the body from scrolling when the modal is open
       * 
       */

      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      populateModal(JSON.parse(story));
      setupSlider();
      vis.showById("story-modal");
    })
  })
}

document.addEventListener("turbolinks:load", () => {
  setupSculptureMap();
  hadnleTabClick();
});

