import React from "react"
import PropTypes from "prop-types"
import Loading from "./Loading"
import Message from "./Message"
import NewMessage from "./NewMessage"
import getToken from '../app/getToken';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sending: false,
      sendingMessage: 'Sending Message...',
      messages: [],
      error: null,
      body: '',         // for new message
    };
    this.renderMessages = this.renderMessages.bind(this);
    this.fetchMessages = this.fetchMessages.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteConversation = this.deleteConversation.bind(this);
  }


  componentDidMount() {
    setTimeout(this.fetchMessages, 500)

  }


  fetchMessages(){
    fetch(this.props.messages_url + '.json')
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error, loading:false })
        else this.setState({ messages:res.data, loading:false }, this.scrollToMostRecent)
      })
      .catch(e => this.setState({ error:e, loading:false }))
  }


  renderMessages(){
    if(this.state.messages.length > 0){
      return this.state.messages.map(m => <Message
        user={this.props.user}
        key={m.id}
        message={m}
        // handleReport={this.reportMessage}
      />)
    } else {
      return <div className="lead italic text-center dark-gray">
        No Messages. Write one below.
      </div>
    }
  }


  scrollToMostRecent(){
    if(this.state.messages.length > 2){
      const id = 'message-' + this.state.messages[this.state.messages.length - 2].id;
      const message = document.getElementById(id);
      if(message) message.scrollIntoView({behavior: "smooth"});
    }
  }


  handleSubmit(e){
    e.preventDefault();

    this.setState({sending:true, sendingMessage: 'Sending Message...' });

    const message = {
      user_id: this.props.user.id,
      conversation_id: this.props.conversation.id,
      body: this.state.body
    }

    fetch(this.props.new_message_url, {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({ message: message })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error:res.error, sending:false })
        else this.setState({ sending:false, body:'' }, this.fetchMessages);
      })
      .catch(e => this.setState({error:e}))
  }


  // Delete the conversation and redirect back
  // to the other user in the conversation.
  deleteConversation(){
    this.setState({ sending:true, sendingMessage:'Deleting Conversation' })

    fetch('/conversations/' + this.props.conversation.id, {
      method: 'DELETE',
      headers: HEADERS
    })
      .then(res => res.json())
      .then(res => {
          if(!res.success) this.setState(({ error: 'An error occurred', sending:false }));
          else window.location.replace(this.props.cancel_redirect);
      })
      .catch(e => this.setState({ sending:false, error:e }))
  }


  render () {

    const anyMessages = this.state.messages.length > 0;

    if(this.state.loading) return <div className="row align-center mmt">
      <Loading class='h4 text-center' message='Loading messages...' />
    </div>

    return (
      <React.Fragment>
        <ul
          className="no-style-list nm column callout cream-bg mmb"
        >
          {this.renderMessages()}
        </ul>

        <NewMessage
          body={this.state.body}
          handleChange={(e) => this.setState({ body: e.target.value })}
          handleSubmit={this.handleSubmit}
        />

        <button
          onClick={anyMessages ? () => window.history.back() : this.deleteConversation}
          className="button expanded smt"
        >
          {anyMessages ? 'Go Back' : 'Cancel'}
        </button>



        {this.state.sending && <div
            className="modal-container blur-bg xlp row align-center-middle"
          >
            <Loading
              message={this.state.sendingMessage}
              class='h4 white'
            />
          </div>
        }



      </React.Fragment>
    );
  }
}

export default Conversation
