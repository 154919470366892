// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

// This basic swiper consist of 1 per window slide with navigation and pagination
// You can include or exclude pagination and navigation in the haml 
// To create new slider just add 'basic-swiper' class to the swiper container
// For reference see app/views/peel_towert/_swiper.html.haml

document.addEventListener("turbolinks:load", function () {
  const swipers = document.querySelectorAll('.basic-swiper');

  for(let i = 0; i < swipers.length; i++){
    const nextEl = '#' + swipers[i].id + '-next';
    const prevEl = '#' + swipers[i].id + '-prev';
    const selector = '#' + swipers[i].id;

    const swiper = new Swiper(selector, {
      modules: [Pagination, Navigation],
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl
      },
      autoHeight: true
      // swipe one slide at a time
    });
  }
});