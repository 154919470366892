import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';

// HOW THIS WORKS:
// We can only load the <audio> and AudioContext after a user touch 
// (iOS compatibility). Therefore we must do the following:
// 
// 1. Render the UI without preloading the <audio>
// 2. On the first Play press, this.state.loading is true so we
//    - create the AudioContext
//    - set this.state.playAfterLoading to true 
//    - load the <audio> tag
// 3. The <audio> onLoad method will play the <audio> tag
//    because of this.state.playAfterLoading, then set it to false
//    to avoid retriggering.
// 4. Any further presses of play will just go ahead and play <audio>

const getAudioContext = () => {
  const ctx = window.AudioContext || window.webkitAudioContext;
  return new ctx;
}

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      playing: false,
      loop: false,
      rate: 1,
      playAfterLoading: false
    };
    this.audioContext = null;
    this.audio = React.createRef();
    this.track = null;
    this.onLoad = this.onLoad.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleEnded = this.handleEnded.bind(this);
    this.toggleRate = this.toggleRate.bind(this);
  }

  onLoad(){
    console.log('loaded')
    if(!this.track){
      this.track = this.audioContext.createMediaElementSource(
        this.audio.current
      );
      this.track.connect(this.audioContext.destination);
    }

    const playCallback = () => {
      if(this.state.playAfterLoading){
        this.handlePlay();
        this.setState({ playAfterLoading: false })
      }
    }

    this.setState({ loading: false }, playCallback);
  }

  handlePlay(){
    if(!this.state.loading){
      this.play();
    } else {
      this.setupAudioAndPlay()
    }
  }

  setupAudioAndPlay(){
    this.audioContext = getAudioContext();
    this.audioContext.resume();
    this.setState(
      { playAfterLoading: true }, 
      () => this.audio.current.load()
    )
  }

  play(){
    if(this.audioContext.state === 'suspended') this.audioContext.resume();
    if(this.state.playing){
      this.audio.current.pause();
      this.setState({ playing: false});
    } else {
      this.audio.current.play();
      this.setState({ playing: true});
    }
  }

  handleEnded(){
    this.setState({playing: false});
  }

  toggleRate(){
    const rate = this.state.rate === 1 ? 0.5 : 1;
    this.setState(
      { rate: rate },
      () => this.audio.current.playbackRate = rate
    );
  }

  render () {
    const {
      rate,
      loading,
      playing,
      loop
    } = this.state;

    if(!this.props.audio) return null;

    return (
      <div className='row align-center fade-in'>
        <audio
          preload='none'
          ref={this.audio}
          src={this.props.audio.src}
          alt={this.props.audio.name}
          loop={loop}
          onLoadedData={this.onLoad}
          onEnded={this.handleEnded}
          crossOrigin="anonymous" // required for AWS CORS
        />

        <div className='audio-player-container row-or-column'>
          <button
            className={`button audio-player ${loading ? '' : 'loaded'} ${playing ? 'playing' : ''}`} onClick={this.handlePlay}>

            <label>{playing ? 'Playing...' : this.props.text}</label>
            <img src={this.context.images.listening} alt="headphones" className='audio-player-icon'/>

          </button>
          <div className='column-or-row fn-button-container align-justify'>
            <button
              className={`lf-audio-fn-button slow ${rate === 0.5 ? 'selected' : ''}`}
              onClick={this.toggleRate}
            >
              <img
                className="turtle-icon"
                src={this.context.images.turtle_white}
                alt='turtle'
              />
              <img
                className="replay-icon"
                src={this.context.images.replay_white}
                alt='replay'
              />
            </button>
            <button
              onClick={() => this.setState({ loop: !loop })}
              className={`lf-audio-fn-button ${loop ? 'loop' : ''}`}
            >

              <img
                className={`replay-icon ${loop ? 'fa-spin' : ''}`}
                src={this.context.images.replay_black}
                alt='replay'
              />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AudioPlayer.propTypes = {
  audio: PropTypes.object,
  text: PropTypes.string,
  audibleId: PropTypes.number,
};

AudioPlayer.defaultProps = {
  text: 'Click to listen',
};

AudioPlayer.contextType = MyContext;

export default AudioPlayer;
