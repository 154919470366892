import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext';

class PreviousQuestionButton extends React.Component {
  render () {
    return <button
      className={`button primary-bg ${this.props.visible ? '' : 'visually-hidden'}`}
      onClick={this.props.handleClick}
    >
      {this.props.buttonText} 
    </button>
  }
}

PreviousQuestionButton.propTypes = {
  handleClick: PropTypes.func,
  buttonText: PropTypes.string,
  visible: PropTypes.bool
};

PreviousQuestionButton.contextType = MyContext;

export default PreviousQuestionButton;
