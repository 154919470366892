export const style = (selector, correct = true) => {
  const element = document.querySelector(selector);
  if(element) element.classList.add(correct ? 'correct' : 'incorrect');
}


export const clearCorrectStyles = () => {
  let elements = document.querySelectorAll(`.correct`);
  for(let i = 0; i < elements.length; i++){
    elements[i].classList.remove('correct');
  }

  elements = document.querySelectorAll(`.incorrect`);
  for(let i = 0; i < elements.length; i++){
    elements[i].classList.remove('incorrect');
  }
}


export const clearStyleFor = (selector) => {
  const element = document.querySelector(selector);
  if(element) element.classList.remove('correct', 'incorrect');
}
