import React from "react";
import PropTypes from "prop-types";
import MyContext from './MyContext'
import OptionButton from './OptionButton';;

class MultipleChoiceQuestion extends React.Component {
  render () {
    const {
      selectedAnswer,
      options,
      handleOptionSelect,
    } = this.props;

    const buttons = options.map((o, i) => {
      return <OptionButton answer={selectedAnswer} handleClick={handleOptionSelect} option={o} key={'option-' + i} id={'option-' + o} />
    })

    return (
      <section className="grid-x align-center">
        {buttons}
      </section>
    );
  }
}

MultipleChoiceQuestion.propTypes = {
  handleOptionSelect: PropTypes.func,
  options: PropTypes.array,
  selectedAnswer: PropTypes.string
};

MultipleChoiceQuestion.contextType = MyContext;

export default MultipleChoiceQuestion
