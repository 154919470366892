import React from "react"
import PropTypes from "prop-types"
import Modal from "./Modal";
import * as vis from '../app/visibility';

class QuizLoginPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
  }
  
  hide(){
    vis.fadeOutById('quiz-login-prompt');
  }

  render () {
    return (
      <Modal
        outerId="quiz-login-prompt"
        outerContainerClasses={`modal-container grid-x align-center-middle toggle-trans trans`}
        onModalClose={this.hide}
        innerContainerClasses='modal-inner-container cell small-10 medium-8 large-6 xlarge-5 xxlarge-4 white-background text-center'
      >
        <p className="h4">
          To continue, please log in.
        </p>

        <p className="lead lmb">
          If you haven't signed up yet, it takes 2 minutes.
        </p>

        <div className="grid-container fluid">
          <div className="grid-x grid-margin-x grid-margin-y align-center">
            <a 
              href={`/login?redirect_to=${this.props.redirect}#quiz`}
              className="button smb cell small-8 medium-6 large-4 xlarge-4"
            >
              <i className="fa fa-sign-in smr" aria-hidden="true"></i>
              Login
            </a>

            <a 
              href={`/join?redirect_to=${this.props.redirect}#quiz`}
              className="button smb cell small-8 medium-6 large-4 xlarge-4"
            >
              <i className="fa fa-pencil-square-o smr" aria-hidden="true"></i>
              Sign Up
            </a>

            {/* <button 
              onClick={this.hide}
              className="button smb cell small-8 medium-6 large-4 xlarge-4"
            >
              Continue
            </button> */}
          </div>
        </div>
      </Modal>
    );
  } 
}

QuizLoginPrompt.propTypes = {
  onModalClose: PropTypes.func,
};
export default QuizLoginPrompt
