import React from "react";
import PropTypes from "prop-types";
import FreeTextQuestion from './FreeTextQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import DotCounter from './DotCounter';
import MyContext from './MyContext';
import QuizComplete from './QuizComplete';
import Errors from './Errors';
import RevealAnswer from './RevealAnswer';
import PreviousQuestionButton from './PreviousQuestionButton';
import * as correctStyler from '../app/correctStyler';
import * as solutions from '../app/solutions';
import * as vis from '../app/visibility';
import {normalize} from '../app/normalize';
import parseForInvalidCharacters from './parseForInvalidCharacters';
import mergeSolutions from './mergeSolutions';
import NextButton from './NextButton';
import QuizLoginPrompt from './QuizLoginPrompt';
import { areaFactors } from "@turf/helpers";

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      solutions: this.props.solutions,
      current: 0,
      answer: '',
      answered: false,
      errors: null,
      feedback: '',
      attempts: 0,
      correctlyAnswered: false,
      showFinish: false
    };
    this.timer = null;
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createAnswer = this.createAnswer.bind(this);
    this.lastQuestion = this.lastQuestion.bind(this);
    this.nextButtonText = this.nextButtonText.bind(this);
    this.loadPrevious = this.loadPrevious.bind(this);
  }

  componentDidMount() {
    if(this.props.user) this.loadSolution();
  }

  loadSolution(){
    const solution = this.state.solutions[this.getCurrent().id];
    if(solution){
      this.setState(
        { answer: solution.body },
        this.checkAnswer
      )
    }
  }

  isMultipleChoice(){
    return this.getCurrent().multiple_choice
  }

  handleChange(e){
    correctStyler.clearCorrectStyles()
    this.setState({ answer: e.target.value, feedback: '' });
  }

  handleOptionSelect(value){
    if(this.timer) clearTimeout(this.timer);
    this.setState(
      { answer: value, feedback: '' }, 
      correctStyler.clearCorrectStyles()
    )
  }

  updateUI(correct, value=undefined){
    correctStyler.clearCorrectStyles();
    const input = document.querySelector('.lf-input');
    if(input) correctStyler.style('.lf-input', correct);
    if(value && this.isMultipleChoice()) {
      const parsedValue = parseForInvalidCharacters(value);
      const normalizedValue = normalize(parsedValue, false)
      correctStyler.style('#option-' + normalizedValue, correct);

    }
    this.setState({ feedback: this.feedback() })
  }

  // Clear incorrect feedback after a few seconds
  // UNUSED at present
  clearIncorrectUI(){
    if(!this.isCorrect()){
      if(this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(
        () => {
          this.setState({ feedback: '', answer: '' });
          correctStyler.clearCorrectStyles();
        }, 
        2000
      )
    }
  }

  feedback(){
    return this.isCorrect() ? this.correctMessage() : this.inCorrectMessage();
  }

  inCorrectMessage(){
    let msg = 'Oh no. Try again!';
    if(this.getCurrent().require_special_characters){
      msg = msg.concat(" Check your spelling or make sure you use the right accents or special characters.")
    }
    return msg;
  }

  checkAnswer(){
    if(this.props.user){
      this.setState({ 
        answered: true, 
        attempts: this.state.attempts + 1, 
        correctlyAnswered: this.isCorrect() 
      });
      this.updateUI(this.isCorrect(), this.state.answer);
      this.createAnswer();
    } else {
      vis.fadeInById('quiz-login-prompt');
    } 
  }

  createAnswer(){
    solutions.create(
      {
        soluble_id: this.getCurrent().id,
        soluble_type: 'Question',
        body: this.state.answer,
        correct: this.isCorrect(),
        user_id: this.props.user
      },
      (solution) => {
        mergeSolutions(
          solution, 
          this.state, 
          this, 
          () => this.setState({ showFinish: solution.complete }, this.highlightBackToTrailButton)
        )
      },
      (e) => {
        console.log(e)
        this.setState(
          {errors: e},
          () => { console.log(e) }
        )
      }
    )
  }

  highlightBackToTrailButton(){
    if(this.lastQuestion()) {
      const backToTrail = document.querySelector('#back-to-trail');
      if(backToTrail) backToTrail.classList.add('jump-up');
      setTimeout(
        () => backToTrail.classList.remove('jump-up'),
        2000
      )
    }
  }

  getAnswers(){
    return this.getCurrent().answers.map(a => normalize(a))
  }

  isCorrect(){
    if(this.getCurrent().check_for_correct_answer){
      return this.getAnswers().includes(normalize(this.state.answer));
    } else return true;
  }

  nextButtonText(){
    return this.lastQuestion() ? 'Next Exercise' : 'Next';
  }

  lastQuestion(){
    return this.state.current === this.props.quiz.questions.length - 1
  }

  next(forward=true){
    correctStyler.clearCorrectStyles();
    const change = forward ? 1 : -1;
    this.setState({
      current: this.state.current + change,
      answer: '',
      answered: false,
      errors: null,
      feedback: '',
      attempts: 0
    }, this.loadSolution)
  }

  handleNext(){
    if(!this.lastQuestion()){
      this.updateUI();
      this.next();
    } else {
      redirectToExerciseOrLesson(this.props)
    }
  }

  correctMessage(){
    return this.lastQuestion() ? "Nice one! You're all done." : 'Excellent!'
  }

  getCurrent(){
    return this.props.quiz.questions[this.state.current];
  }

  loadPrevious(){
    if(this.state.current > 0) this.next(false);
  }

  solutionFor(question){
    return this.state.solutions[question.id]
  }

  render () {
    const { quiz, gameable, user } = this.props;
    const { questions } = quiz;
    const {
      current,
      answered,
      feedback,
      errors,
      answer,
      attempts,
      correctlyAnswered,
      showFinish
    } = this.state;
    const question = questions[current];

    if(!question) return <p className='italic h1 dark-gray'>No Questions</p>

    let revealText = 'See Example';
    let revealButtonVisible = this.getCurrent().answers.length > 0 ? attempts > 0 : false;

    if(this.getCurrent().check_for_correct_answer){
      revealText = 'Reveal Answer';
      revealButtonVisible = attempts > 1 && !correctlyAnswered;
    }

    const context = {
      images: this.props.images
    }

    const questionsCounterData = this.props.quiz.questions.map(q => {
      return this.solutionFor(q) ? this.solutionFor(q).correct : null;
    });

    return (
      <MyContext.Provider  value={context} >
        <div className='quiz text-center'>

          <h4 className='title-line-header'>
            <span className="title-line"/>
            Take the quiz!
            <span className="title-line"/>
          </h4>

          <div className="inner-quiz mmb">
            <label className='question'>Q. {question.name}</label>

            {this.isMultipleChoice() &&
              <MultipleChoiceQuestion
                selectedAnswer={answer}
                options={question.options}
                handleOptionSelect={this.handleOptionSelect}
              />
            }

            {!this.isMultipleChoice() &&
              <FreeTextQuestion
                handleChange={this.handleChange}
                answer={answer}
              />
            }

            <button 
            className="button hollow expanded smb quiz-submit"
              onClick={this.checkAnswer}
              disabled={!answer}
            >
              {feedback ? feedback : 'Submit'}
            </button>

            <div className="grid-x align-justify">
              <div className="cell small-6 medium-4 row small-order-1 medium-order-1 xsmb">
                <PreviousQuestionButton
                  visible={current > 0}
                  handleClick={this.loadPrevious}
                  buttonText='Previous'
                />
              </div>

              <div 
                className="cell medium-4 small-order-3 medium-order-2 column align-center"
              >
                <DotCounter questions={questionsCounterData} length={questions.length} index={current} />
              </div>

              <div className="cell small-6 medium-4 row align-right small-order-2 medium-order-3 xsmb">
                <NextButton
                  visible={!!answered && !this.lastQuestion()}
                  last={this.lastQuestion()}
                  onClick={this.handleNext}
                  text={this.nextButtonText()}
                />
              </div>
            </div>
            
            <RevealAnswer
              buttonVisible={revealButtonVisible}
              answers={this.getCurrent().answers}
              buttonText={revealText}
            />
            
            <Errors errors={errors} />

            <QuizLoginPrompt redirect={this.props.gameable_url}/>

            <QuizComplete 
              trailId={gameable.trail_id} 
              visible={showFinish} 
              onModalClose={() => this.setState({ showFinish: false })}
            />
          </div>
        </div>
      </MyContext.Provider>
    );
  }
}

Quiz.propTypes = {
  quiz: PropTypes.object,
  lessonId: PropTypes.number,
  user: PropTypes.number
};

Quiz.contextType = MyContext;

export default Quiz
