// SCROLL TO

// Set up a scrollToId listener for every element with .scroll-to class.
// Get target of element to scroll to from data-scroll-to.
document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('.scroll-to');

  for(let i = 0; i < elements.length; i++){
    const el = elements[i];
    const id = el.getAttribute('data-scroll-to');

    if(id) el.addEventListener('click', (e) => {
      e.preventDefault(); // prevent links firing
      scrollToId(id);
    })
  }
});


// onClick for all scroll tos
const scrollToId = (id) => {
  const target = document.getElementById(id);
  if(target) target.scrollIntoView({ behavior: 'smooth' });
}
