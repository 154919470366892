import _ from 'lodash';

// We need to massage string answers to avoid being too restrictive:
// - downcase strings
// - remove full stops at the end of answers
// - optionally remove all special characters

export const normalize = (string, requireSpecialCharacters=false) => {
  string = removeLastCharacterIfFullStop(string);
  if (requireSpecialCharacters) return string.toLowerCase().trim();
  return _.deburr(string.toLowerCase().trim())
}

export const removeApostrophes = (string) => {
  return string.replaceAll(/'/g, '-');
}

export const removeLastCharacterIfFullStop = (string) => {
  if (string.endsWith('.')) return string.slice(0, -1);
  return string;
}
