// UNSURE if this is used

import * as visibility from './visibility';

const toggleTarget = (e) => {
  const el = e.target.parentNode;

  const data = el.dataset;
  if('toggleSwitch' in data && 'toggleTarget' in data){
    const target = document.getElementById(data.toggleTarget);
    if(target) visibility.toggle(target);
  }
}


document.addEventListener('click', toggleTarget);
