import mapboxgl from 'mapbox-gl';
import * as colors from './colors';
import { JsRoutesRails } from './routes';
 

export const mapStyle = 'mapbox://styles/icecreamneil/clnkcte20001001qqdptagq3o';
export const satelliteStyle = 'mapbox://styles/icecreamneil/ck2oq0a0e08ln1cmf5p38aj2o';

// Development
export const SANDBOX_STYLE = 'mapbox://styles/icecreamneil/clnkcte20001001qqdptagq3o';


export const bounds = new mapboxgl.LngLatBounds(
  [-6.218632, 55.159920],
  [-3.439832, 56.194872]
);


const gcCenter = [-4.696521, 55.704228];


const token = 'pk.eyJ1IjoiaWNlY3JlYW1uZWlsIiwiYSI6ImNqOTc5OTUzMTBiNzIycXF0bjB1bjFiOTYifQ.K2mrWaQZhVc7rLPLI5oAsw';


export const mapOptions = {
  // container: 'garnock-map-container',
  style: mapStyle,
  zoom: 9.83,
  center: [-3.887319731, 55.63834655],
  pitch: 0,
  minZoom: 8,
  maxZoom: 20,
  // maxBounds: bounds,
  bearing: 0,
  attributionControl: false, // attr control inserted below
}


export const setupMap = function (container, options = undefined) {
  // merge new options if given. Probably better way
  if (options) combine(mapOptions, options);
  mapOptions.container = container;
  mapboxgl.accessToken = token;
  const map = new mapboxgl.Map(mapOptions);
  // attribution
  map.addControl(new mapboxgl.AttributionControl({ compact: false }));
  new mapboxgl.Marker()
    .setLngLat([-0.1404545, 51.5220163])
    .addTo(map);
  return map;
}


// check if new_obj key not present and not empty (null)
// then combine
const combine = (originalObj, newObj) => {
  Object.keys(originalObj).forEach(k => {
    if (newObj.hasOwnProperty(k) && newObj[k]) originalObj[k] = newObj[k];
  })
}


export const addControls = (map, position) => {
  const ctrl = new mapboxgl.NavigationControl;
  map.addControl(ctrl, position);
}


// export const addLine = (map, dataOrUrl, layerId) => {
//   console.log("layer id: ", layerId)
//   map.addSource(layerId, {
//     "type": "geojson",
//     "data": dataOrUrl
//   })

//   map.addLayer({
//     "id": layerId,
//     "type": "line",
//     "source": layerId,
//     "layout": {
//       "line-cap": "round",
//       "line-join": "round",
//     },
//     "paint": {
//       "line-color": colors.pink,
//       "line-width": 3,
//     }
//   }, 'places')
// }

export const addLine = (map, dataOrUrl, layerId) => {
  console.log("layer id: ", layerId);
  map.addSource(layerId, {
    "type": "geojson",
    "data": dataOrUrl
  });

  // Check if the 'places' layer exists
  if (!map.getLayer('places')) {
    console.log('Layer "places" does not exist. Adding layer without before parameter.');
    map.addLayer({
      "id": layerId,
      "type": "line",
      "source": layerId,
      "layout": {
        "line-cap": "round",
        "line-join": "round",
      },
      "paint": {
        "line-color": colors.pink,
        "line-width": 3,
      }
    });
  } else {
    map.addLayer({
      "id": layerId,
      "type": "line",
      "source": layerId,
      "layout": {
        "line-cap": "round",
        "line-join": "round",
      },
      "paint": {
        "line-color": colors.pink,
        "line-width": 3,
      }
    }, 'places'); // Add layer before 'places'
  }
}




export const addSymbolLayer = (map, dataOrUrl, layerId) => {
  map.addSource(layerId, {
    "type": "geojson",
    "data": dataOrUrl
  });

  map.addLayer({
    "id": layerId,
    "type": "symbol",
    "source": layerId,
    "layout": {
      "icon-image": "{icon}",
      "icon-anchor": "bottom",
      "symbol-z-order": "viewport-y",
      "icon-size": 0.5
    }
  })

  // setupPopup(layer);
  // setupCursor(layer);
}

export const addGeoJsonLayer = (map, dataOrUrl) => {
  map.addSource('sculptures', {
    "type": "geojson",
    "data": dataOrUrl
  });



  map.addLayer({
    "id": 'sculptures-points',
    "type": "symbol",
    "source": 'sculptures',
    "layout": {
      "icon-image": "built",
      "icon-anchor": "bottom",
      "icon-allow-overlap": false,
      "symbol-z-order": "viewport-y",
      "icon-size": 0.5
    },
    'filter': ['==', '$type', 'Point']
  })
}
export const zoomToRoute = (map, route) => {
  const { coordinates } = route.features[0].geometry;

  setTimeout(
    () => map.easeTo({
      center: coordinates[0],
      zoom: 12.8,
      duration: 1000
    }),
    250
  );
}



export const setupPopup = (map, layer) => {
  map.on('click', layer, (e) => {
    const { properties } = e.features[0];
    const { coordinates } = e.features[0].geometry

    // TAKEN from: https://docs.mapbox.com/mapbox-gl-js/example/popup-on-click/
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const html = createPointHTML(properties)

    // render HTML into Popup
    new mapboxgl.Popup({ offset: 15 })
      .setLngLat(coordinates)
      .setDOMContent(html)
      .addTo(map);

    // map.easeTo({ center: coordinates });
  })
}




export const addSculpturesLayer = (map, dataOrUrl) => {
  
  map.addSource('sculptures', {
    "type": "geojson",
    "data": dataOrUrl
  });

  map.addLayer({
    "id": 'sculptures-points',
    "type": "symbol",
    "source": 'sculptures',
    "layout": {
      "icon-image": "waterdrop",
      "icon-anchor": "bottom",
      "icon-allow-overlap": true,
      "symbol-z-order": "viewport-y",
      "icon-size": 0.6
    },
  })
}



const createPointHTML = (properties) => {
  const container = document.createElement('div');

  const title = document.createElement('h5');
  title.innerHTML = properties.title;

  container.appendChild(title);

  const buttonContainer = document.createElement('div');
  buttonContainer.classList.add('flex-container', 'align-center');

  const link = document.createElement('a');
  link.href = JsRoutesRails.point_path({
    id: properties.id,
  });

  const button = document.createElement('button');
  button.classList.add('button', 'expanded');
  button.innerHTML = 'View'

  // put button in link, then link in container
  link.appendChild(button);
  buttonContainer.appendChild(link);

  const editButton = document.createElement('button');
  editButton.classList.add('button', 'expanded');
  editButton.innerHTML = 'Edit';

  const editLink = document.createElement('a');
  editLink.classList.add('sml')
  editLink.href = JsRoutesRails.edit_point_path({
    id: properties.id,
  });

  // put button in link, then link in container
  editLink.appendChild(editButton);
  if (onEditTrailPage()) buttonContainer.appendChild(editLink);

  container.appendChild(buttonContainer);
  return container;
}


// Extract/Format Coordinates from obj
// Multiple conditions allow for 'click' and marker objects
export const getCoords = (object) => {
  const { lngLat, _lngLat } = object;
  if (lngLat) return [lngLat.lng, lngLat.lat];
  if (_lngLat) return [_lngLat.lng, _lngLat.lat];
}


export const setSatStyle = (map, callback) => {
  map.setStyle(satelliteStyle, { diff: false });
}

export const setMapStyle = (map, callback) => {
  map.setStyle(mapStyle, { diff: false });
}


const onEditTrailPage = () => {
  const body = document.getElementsByTagName('body')[0];
  return body.classList.contains('trails') && body.classList.contains('edit');
}
