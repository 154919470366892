import React from "react";
import PropTypes from "prop-types";
import Loading from "./Loading";
import CommentList from "./CommentList";
import NewComment from "./NewComment";
import CommentContext from "./CommentContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faComment,
  faComments,
  faBan,
} from "@fortawesome/free-solid-svg-icons"
import getToken from '../app/getToken';

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}


class CommentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      comments: [],
      newComment: false,
      body: '',
      editing: null,
      errors: null,
      disabled: this.props.disabled,
      // NOTE on this ^ ...
      //  normally copying props into state is BAD!
      //  Here it is fine as CommentsContainer is only rendered by its parent
      //  view once when the page loads. No mutations of this.state will change
      //  the this.props.disabled.
      //  See https://medium.com/@justintulk/react-anti-patterns-props-in-initial-state-28687846cc2e
    };
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancelEditing = this.handleCancelEditing.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleToggleComments = this.handleToggleComments.bind(this);
    this.hideToggleCommentButtonOnParentPage = this.hideToggleCommentButtonOnParentPage.bind(this);
    this.clearFlash = this.clearFlash.bind(this);
    this.newCommentField = React.createRef();
    this.recordUrl = this.props.url.slice(0, this.props.url.search('comments'));
  }


  componentDidMount() {
    this.fetchData();
  }


  // Get those lovely comments!
  fetchData(){
    fetch(this.props.url)
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ errors: res.error })
        else this.setState({ loading: false, comments: res.data })
      })
  }


  // Show NewComment form and reset body
  handleAddComment(){
    if(this.props.user){
      this.setState(
        { newComment: true, body: '', editing: null },
        () => this.newCommentField.current.focus()
      )
    } else {
      window.location = this.props.login
    }
  }


  // Update state based on Form input
  handleChange(e){
    const newState = this.state;
    newState.body = e.target.value;
    this.setState(newState);
  }


  // Submit CREATE and EDIT requests, then update Comment list
  handleSubmit(e){
    e.preventDefault();

    let url = this.props.url;
    if(this.state.editing) url += `/${this.state.editing}`;

    fetch(url, {
      method: `${this.state.editing ? 'PATCH' : 'POST'}`,
      headers: HEADERS,
      body: JSON.stringify({ comment: { body: this.state.body } })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error })
        else {
          this.setState({ newComment: false, body: '', editing: null });
          this.fetchData();
        }
      })
      .catch(e => { this.setState({ error:e }) })
  }


  // Submit DELETE requests, update Comments
  handleDelete(comment){
    fetch(`${this.props.url}/${comment.id}`, {
      method: 'DELETE',
      headers: HEADERS
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ error: res.error })
        else {
          this.setState({ flash: 'Deleted!' }, this.clearFlash);
          this.fetchData();
        }
      })
      .catch(e => { this.setState({ error:e }) })
  }


  handleEdit(comment){
    this.setState({ editing:comment.id, body:comment.body  })
  }


  handleCancelEditing(e){
    e.preventDefault();
    this.setState({ editing:null, body:'' });
  }

  // UPDATE Commentable Record to disable comments, then hide all output
  handleToggleComments(commentsEnabled){
    let url = this.props.url.slice(0, this.props.url.search('comments'));
    const body = {};
    body[this.props.type] = { allows_comments: commentsEnabled };

    fetch(url, {
      method: 'PATCH',
      headers: HEADERS,
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ errors: res.error })
        this.setState({ disabled: !commentsEnabled }, this.hideToggleCommentButtonOnParentPage)
      })
      .catch(e => this.setState({ errors:e }))
  }


  // Within main page there is another Enable/Disable Comments button.
  // We need to hide this after the Enable/Disable Comments button within
  // THIS component has been pressed to avoid confusion.
  hideToggleCommentButtonOnParentPage(){
    const button = document.getElementById('allow-comments-button');
    // button is contained within a form. this is what must be hidden
    if(button) button.parentElement.classList.add('hidden');
  }


  // TODO
  // Hide a flash, BUT I HAVENT'T MADE A FLASH YET
  clearFlash(){
    console.log('clear!');
  }


  render () {

    const {
      comments,
      loading,
      newComment,
      body,
      editing,
      disabled,
    } = this.state;

    const {
      commentable,
      user,
      url,
      type
    } = this.props;


    // Extract root domain by slicing before 'type',
    // e.g 'item' from 'www.placethatweknow.org/items/1-an-item'
    const domain = url.slice(0, url.search(type));

    if(loading) return <Loading />

    // Render nothing if Comments are disabled for Commentable record
    if(disabled){
      if(user && user.admin) {
        return (
          <div className="callout dark-gray cream-bg text-center lead italic">

            <p className='smb'>Comments have been disabled.</p>

            <button
              className="button lmb"
              onClick={() => this.handleToggleComments(true)}
            >
              Enable Comments
            </button>

          </div>
        )
      } else {
        return null;
      }
    }

    const context = {
      context: {
        commentable:commentable,
        user:user,
        body:body,
        handleChange: this.handleChange,
        handleCancelEditing: this.handleCancelEditing,
        handleSubmit: this.handleSubmit,
        handleEdit: this.handleEdit,
        handleDelete: this.handleDelete,
        editing: editing,
        domain: domain
      }
    }

    let header = '0 Comments';
    if(comments.length > 0){
      header = `${comments.length} Comment${comments.length > 1 ? 's' : ''}`;
    }


    return (
      <CommentContext.Provider
        value={context}
      >
        <section>

          <header className="row align-justify smb">

            {/* X COMMENTS */}
            <h5 className="nm">
              <FontAwesomeIcon
                icon={faComments}
                className='smr xsml'
              />
              {header}
            </h5>

            {/* BUTTONS */}
            <span>
              {/* ADD COMMENT */}
              <button
                className="button smr"
                onClick={this.handleAddComment}
                disabled={newComment}
              >
                <FontAwesomeIcon
                  icon={faComment}
                  className='xsmr'
                />
                Add Comment
              </button>

              {/* DISABLE COMMENT */}
              {user && user.admin &&
                <button
                  className="button"
                  onClick={() => this.handleToggleComments(false)}
                >
                  <FontAwesomeIcon
                    icon={faBan}
                    className='smr xsml'
                  />
                  Disable Comments
                </button>
              }
            </span>
          </header>


          {/* COMMENT FORM */}
          {newComment &&
            <NewComment
              ref={this.newCommentField}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              cancel={() => this.setState({ newComment:false, body:'' })}
              body={body}
            />
          }

          <CommentList
            comments={comments}
          />

        </section>

      </CommentContext.Provider>
    )
  }
}

export default CommentsContainer;


CommentsContainer.propTypes = {
  url: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    admin: PropTypes.bool
  })
}
