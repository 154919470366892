import * as colors from './colors';


const drawStyles = [
  //  LINES
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "butt",
      "line-join": "miter",
      "line-miter-limit": 2,
    },
    "paint": {
      "line-color": colors.pink,
      "line-dasharray": [4, 1],
      "line-width": 4
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["==", "active", "true"]],
    "paint": {
      "circle-radius": 8,
      "circle-color": "#FFF"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 6,
      "circle-color": colors.lightBlue,
    }
  },
  // midpoints active
  {
    "id": "gl-draw-midpoint-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 5,
      "circle-color": '#FFF',
    }
  },
  // midpoints
  {
    "id": "gl-draw-midpoint",
    "type": "circle",
    "filter": ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 3,
      "circle-color": colors.lightestBlue
    }
  }
];

export default drawStyles;
