// Validation Data for File Types //////////////////////////////////////////////

const fileData = {
  image: {
    limitString: '10MB',
    limit: 10000000,
    quantity: 'MB',
    types: [
      'image/png',
      'image/jpeg',
      'image/gif'
    ],
    typeString: 'png, jpeg or gif'
  },
  audio: {
    limitString: '500MB',
    limit: 500000000,
    quantity: 'MB',
    types: [
      'audio/mp3',
      'audio/mp4',
      'audio/m4a',
      'audio/mpeg',
      'audio/x-wav',
      'audio/wav',
      'audio/aac',
      'audio/flac',
    ],
    typeString: 'mp3, m4a, x-wav, wav, aac or flac'
  },
  video: {
    limitString: '1GB',
    limit: 1000000000,
    quantity: 'GB',
    types: [
      'video/mp4',
      'video/avi',
      'video/x-msvideo',
      'video/wmv',
      'video/x-ms-wmv',
      'video/flv',
      'video/x-flv',
      'video/mov',
      'video/quicktime',
      'video/ogg',
    ],
    typeString: 'mp4, avi, wmv, flv, mov or ogg'
  },
  vr: {
    limitString: '5GB',
    limit: 5000000000,
    quantity: 'GB',
    types: [
      'image/png',
      'image/jpeg',
      'image/gif',
      'video/mp4',
      'video/avi',
      'video/x-msvideo',
      'video/wmv',
      'video/x-ms-wmv',
      'video/flv',
      'video/x-flv',
      'video/mov',
      'video/quicktime',
      'video/ogg',
    ],
    typeString: 'mp4, avi, wmv, flv, mov or ogg'
  },
  application: {
    limitString: '500MB',
    limit: 500000000,
    quantity: 'MB',
    types: [
      'application/pdf',
      'application/ppt',
      'application/msword',
      'application/vnd.ms-powerpoint', 
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/rtf',
      'application/vnd.oasis.opendocument.text'
    ],
    typeString: 'pdf, doc, docx, rtf or odt'
  }
}

export default fileData;
