import * as vis from './visibility';

document.addEventListener('turbolinks:load', () => {
  const cbs = document.querySelectorAll('input[type="checkbox"].check-toggle');
  for(let i = 0; i < cbs.length; i++){

    const show = cbs[i].getAttribute('data-show-on-checked');
    const targets = cbs[i].getAttribute('data-toggle').split(' ');
    for(let j = 0; j < targets.length; j++){
      const target = document.getElementById(targets[j]);
      if(target){
        toggle(target, cbs[i], show);
        cbs[i].addEventListener('change', () => toggle(target, cbs[i]));
      }
    }
  }
});


const toggle = (target, cb, show=null) => {
  if(show){
    if(cb.checked) vis.show(target)
    else vis.hide(target);  
  } else {
    if(cb.checked) vis.hide(target);
    else vis.show(target);
  }
}