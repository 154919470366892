import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPencilAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

const NewComment = React.forwardRef((props, ref) => (
  <form action="">
    <input
      ref={ref}
      className='smb'
      type="text"
      name="comment"
      onChange={props.handleChange}
      plaecholder='Type your comment here...'
      value={props.body}
    />

    {/* SUBMIT / DELETE BUTTONS */}
    <div className="grid-x smb grid-margin-x">

      <button
        className="button cell medium-6 smb-small-only"
        onClick={props.handleSubmit}
      >
        <FontAwesomeIcon
          icon={faPencilAlt}
          className='smr xsml'
        />
        Submit
      </button>

      <button
        className="button cell medium-6"
        onClick={props.cancel}
      >
        <FontAwesomeIcon
          icon={faTimes}
          className='smr xsml'
        />
        Cancel
      </button>

    </div>

  </form>

));

export default NewComment;

NewComment.propTypes = {
  body: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
