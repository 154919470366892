import React from "react"
import PropTypes from "prop-types"
import MyContext from './MyContext';

class NextButton extends React.Component {
  render () {
    const {
      visible,
      last,
      disabled,
      onClick,
      text
    } = this.props;

    return (
      <button
        disabled={disabled}
        className={`button primary-bg toggle-trans ${visible ? '' : 'trans'}`}
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
}

NextButton.propTypes = {
  visible: PropTypes.bool,
  last: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string
};

NextButton.defaultProps = {
  visible: true,
  disabled: false
}

 NextButton.contextType = MyContext;

export default NextButton
