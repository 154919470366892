import { areaFactors } from '@turf/helpers';
import * as vis from './visibility';

let quiz;

document.addEventListener('turbolinks:load', () => {
  quiz = document.getElementById('quiz');
  // if quiz exists, add a click listener to document
  if (quiz) document.addEventListener('click', quizLoginPrompt);
});

const quizLoginPrompt = (event) => {
  //  if quiz contains the event.target, get #logged-in
  if (quiz.contains(event.target)) {
    const loggedIn = document.getElementById('logged-in');
    // if loggedin has attribute data-value, open #quiz-login-prompt
    if (!loggedIn.getAttribute('data-value')){
      vis.fadeInById('quiz-login-prompt');
    }
    document.removeEventListener('click', quizLoginPrompt);
  }
}