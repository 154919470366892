const FADE_TIME = 500

const clickToMaximise = () => {
  const elements = document.querySelectorAll('.click-to-maximise')
  for(let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', () => {
      const src = elements[i].src

      const container = document.createElement('div')
      container.classList.add('trans', 'maximised-image-container')

      const img = document.createElement('img')
      img.src = src
      container.appendChild(img)

      const closeButton = document.createElement('button')
      closeButton.classList.add('close-button')
      closeButton.innerHTML = '&times;'
      container.appendChild(closeButton)
      
      closeButton.addEventListener('click', () => {
        container.classList.add('trans')
        // delete container after transition
        setTimeout(() => { container.remove() }, FADE_TIME)
      })

      document.body.appendChild(container)
      
      setTimeout(() => {
        container.classList.remove('trans')
      }, 10)
    })
  }
}

document.addEventListener('turbolinks:load', clickToMaximise)