import Draw from './drawTrailRoute';
import length from '@turf/length';
import { point, featureCollection, lineString } from '@turf/helpers';
import * as ajax from './ajax';
import * as vis from './visibility';


const msg = "Save this route now? You can return and edit it later.";

const saveOrParentClicked = (e) => {
  return e.target.id === 'save-button' || e.target.parentElement.id === 'save-button'
}


const saveRoute = (e) => {
  if(saveOrParentClicked(e)){
    vis.showById('loading-modal');

    const route = Draw.getAll().features[0]
    const formatted = featureCollection([route])
    const json = JSON.stringify(formatted);

    // insert into form
    const field = document.getElementById('trail_json_route');
    field.value = json;

    // calculate distance
    // const start = route.geometry.coordinates[0];
    // const end = route.geometry.coordinates.pop();
    // const from = point(start);
    // const to = point(end);
    // const dist = distance(from, to);

    const lineToMeasure = lineString(route.geometry.coordinates);
    const lngth = length(lineToMeasure);
    const toMetres = Math.round(lngth * 1000);
    // insert into form
    const distField = document.getElementById('trail_distance');
    distField.value = toMetres;

    const form = field.parentElement;
    form.submit();
  }
}



const buildRequest = (geolocations) => {
  const auth = document.getElementsByName('authenticity_token')[0].value;
  const trailId = document.getElementById('route_trail_id').value;

  const body = {
    authenticity_token: auth,
    route: {
      trail_id: trailId,
      geolocations_attributes: geolocations
    }
  }

  return JSON.stringify(body);
}


// accept array of arrays,
// convert to array of objects with string values
const formatRoute = (coordinatesArray) => {
  return coordinatesArray.map(a => {

    const lng = a[0].toString();
    const lat = a[1].toString();

    return { lng: lng, lat: lat };
  })
}


const handleErrors = (errors) => {
  printErrors(errors);
  renderErrors(errors);
}


const printErrors = (errors) => {
  console.error('Error: There was a problem creating this Route record.');
  errors.forEach(e => console.error('Error: ' + e));
}


const renderErrors = (errors) => {
  const list = document.getElementById('errors-list');
  list.innerHTML = ''; // clear errors

  // add new errors to list
  const elements = errors.forEach(e => {
    const err = document.createElement('LI');
    err.innerHTML = e;
    list.appendChild(err);
  })

  vis.showById('route-error');
}


document.addEventListener('click', saveRoute);
