import React from "react";
import PropTypes from "prop-types";

const ItemList = (props) => {

  let items = <p className="lead italic">No Items available</p>

  if(props.items.length > 0){
    items = props.items.map(item => {
      return <li key={'item' + item.id}>
        <div
          className="primary-bg sp mpl xsmb med-blue-bg-on-hover row align-justify align-middle border-radius"
        >
          {/* This width is required to make ellipsis work */}
          <label className='nm bold text-left ellipsis white' style={{width: '55%'}} >
            {item.name}
          </label>

          <span>
            <button
              onClick={() => props.handleItemSelect(item.id)}
              className="xsp button hollow cream smr"
            >
              Choose
            </button>
            <a
              href={`${props.url}/${item.id}`}
              target='_blank'
              rel='nofollow'
            >
              <button className="xsp button hollow cream">View</button>
            </a>
          </span>
        </div>
      </li>
    })
  }

  return (

    <React.Fragment>

      <ul
        className="callout cream-bg border-radius sp no-bullet-list overflow-auto"
        id='item-list'
      >
        {items}
      </ul>

    </React.Fragment>
  );
}

export default ItemList;
