import React from "react"
import PropTypes from "prop-types"
class NumberLabel extends React.Component {
  render () {
    if(this.props.total > 1) return (
      <label className='question-number bold'>{this.props.current}/{this.props.total}</label>
    );
    else return null;
  }
}

NumberLabel.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};
export default NumberLabel
