import * as get from './get';
import * as vis from './visibility';

document.addEventListener('click', () => {

  const el = document.getElementById('confirm-location');

  if(el){
    // Confirm coordinates are set, then ...
    const latField = get.elementsWhereIdIncludes('_geolocation_attributes_lat');
    const lngField = get.elementsWhereIdIncludes('_geolocation_attributes_lng');
    if(latField.value && lngField.value) {

      // ... hide 'First, click ...'
      vis.hideById('first-click');
      // ... show 'Location set' etc
      vis.showById('confirm-location');
      // ... show Next Options
    }
  }

});
