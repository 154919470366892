import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['leftGradient', 'rightGradient', 'scroller', 'card']
  connect() {
    this.toggleGradient()
  }
  toggleGradient(e) {
    // Called any time the controller is connected to the DOM
    const root = this.element
    console.log("ROOT ", this.root)
    this.leftMostCard = this.cardTargets[0]
    this.rightMostCard = this.cardTargets[this.cardTargets.length - 1]
    console.log("All Cards: ", this.cardsTarget);
    console.log("Left Most Card: ", this.leftMostCard);
    console.log("Right Most Card: ", this.rightMostCard);
    const leftGradient = this.leftGradientTarget
    const rightGradient = this.rightGradientTarget
    const options = {
      root: root,
      rootMargin: '0px',
    }

    function checkRight(entries){
      if (entries[0].isIntersecting && entries[0].intersectionRatio === 1) {
        rightGradient.classList.add('tw-opacity-0');
      }else{
        rightGradient.classList.remove('tw-opacity-0');
      }
    };

    function checkLeft(entries){
      if (entries[0].isIntersecting && entries[0].intersectionRatio === 1) {
        leftGradient.classList.add('tw-opacity-0');
      } else {
        leftGradient.classList.remove('tw-opacity-0');
      }
    };

    const rightObserver = new IntersectionObserver(checkRight, options)
    rightObserver.observe(this.rightMostCard)

    const leftObserver = new IntersectionObserver(checkLeft, options)
    leftObserver.observe(this.leftMostCard)
  }

  scrollRight(){
    // scroll smoothly to the right
    console.log(this.scrollerTarget.scrollWidth)
    this.scrollerTarget.scrollBy({
      left: 300,
      behavior: 'smooth',
    });
    console.log("SCROLLING RIGHT")
  }

  scrollLeft(){
    // scroll smoothly to the right
    this.scrollerTarget.scrollBy({
      left: -300,
      behavior: 'smooth'
    });
    console.log("SCROLLING Left")
  }
}
