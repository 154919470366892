import React from "react"
import PropTypes from "prop-types"
import { Fragment } from "react";
class DotCounter extends React.Component {

  renderDots() {
    const dots = [];
    for(let i = 0; i < this.props.length; i++) {
      dots.push(
        <div 
          key={'dot-' + i} 
          className={`dot ${i === this.props.index ? 'selected' : ''}`}
        />
      );
    }
    return dots;
  }

  renderAnswers() {
    return this.props.questions.map((q, i) => {
      if(q === null) return <div 
        key={'dot-' + i} 
        className={`dot ${i === this.props.index ? 'selected' : ''}`}
      />
      else return <i className={`fa fa-${q ? 'check' : 'times'}-circle ${q ? 'success' : 'warning'} ${i === this.props.index ? 'selected' : ''}`} aria-hidden="true"></i>
    })
  }

  render () {
    return <div className='dot-counter'>{this.renderAnswers()}</div>
  }
}

DotCounter.propTypes = {
  length: PropTypes.node,
  index: PropTypes.node
};

export default DotCounter
