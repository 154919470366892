// After we insert an association using cocoon - e.g an Image for Items - 
// we want the new association to be at the end of the list of associatons.
// For example, given an Item with 3 images, when we click 'Add Image'
// the new Image fields should be after all the other Image fields.

// NOTE: for this to work you must set the Add Association button to
// include the class: 'insert-at-end-of-assoc-fields'

$(document).on('cocoon:after-insert', function(e, newAssocFields, originalEvent) {
  const newAssoc = newAssocFields[0];
  const button = originalEvent.target;
  if(button.classList.contains('insert-at-end-of-assoc-fields')) {
    const assocFieldsContainer = newAssoc.nextElementSibling;
    assocFieldsContainer.appendChild(newAssoc);
    newAssoc.scrollIntoView({behavior: 'smooth'});
  }
});