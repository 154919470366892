
document.addEventListener('turbolinks:load', function(){
  setItemNumber()

  $(document).on('cocoon:after-insert', function() {
    setItemNumber()
  })

  $(document).on('cocoon:after-remove', function() {
    setItemNumber()
  })

  $(document).on('ajax:success', function() {
    setItemNumber()
  })
});


function setItemNumber(selector='h4 span.item-number'){
  const itemNumberElements = document.querySelectorAll(selector)

  const itemNumberElementsArray = Array.from(itemNumberElements)

  const visibleElements = itemNumberElementsArray.filter(el =>
                            !!el.offsetParent
                          )

  if (visibleElements.length > 0){
    visibleElements.forEach(function(element, index){
      element.innerText = index + 1;
    })
  }

}

