import getToken from './getToken'

const HEADERS = {
  'X-CSRF-Token': getToken(),
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}


export const create = (attrs, callback, onError) => {
  fetch('/solutions', {
      method: 'POST',
      headers: HEADERS,
      body: JSON.stringify({ solution: attrs })
    })
      .then(res => res.json())
      .then(res => {
        if(res.success) callback(res.data);
        else onError(res.error);
      })
      .catch(e => { onError(e) })
}
