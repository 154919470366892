import * as vis from './visibility';

export const hideMediaFields = () => {
  const mediaFields = document.querySelectorAll('.media-fields')
  mediaFields.forEach(field => vis.hide(field));
}

export const enableMediaButtons = () => {
  const buttons = document.querySelectorAll('.add-media-item');
  buttons.forEach(b => b.disabled = false);
}

const addMediaItems = (e) => {
  if(e.target.classList.contains('add-media-item')){
    const button = e.target;

    // Enable all add-media-item
    enableMediaButtons()

    // Hide all the Upload media fields
    hideMediaFields();

    // Show file field, e.g #images-fields
    const type = button.getAttribute('data-add')
    const fileField = document.getElementById(`${type}-fields`);
    if(fileField){
      vis.show(fileField)
      button.disabled = true // Disable clicked button
    }
  }
}


// TODO
const cancelMedia = (e) => {
  if(e.target.id === 'cancel-media') {
    hideMediaFields();
    enableMediaButtons();
  }
}

document.addEventListener('click', addMediaItems);
document.addEventListener('click', cancelMedia);
