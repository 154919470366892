import * as vis from './visibility';

// On the VR show page, load the IMAGE via an XMLHttpRequest, then parse image 
// and insert directly into an img element.
// NOTE: We do this to ensure that the correct headers are sent to the AWS S3
// server. Simply inserted an <img> tag with the URL does not send all headers,
// resulting in intermittent CORS errors.
const loadVrAsync = () => {
  if(document.querySelector('body.vr.show')){
    try {
      const url = document.querySelector('#loading-vr').getAttribute('data-url');

      if(url){
        console.log('Loading VR Image');
        var request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.onload = function() {
          if (request.status >= 200 && request.status < 400) {
            console.log(request.response)
            let base64 = Buffer.from(request.response, 'binary').toString('base64')
            const srcValue = "data:image/png;base64," + base64
            renderImage(srcValue)
          } else {
            console.log('error')
          }
        };
        console.log(request)
        request.send();
      }
    } catch(e) { 
      console.log('ERROR', e);
    }
  }
}

const renderImage = (src) => {
  const image = document.createElement('img');
  image.setAttribute('id', 'vr-image');
  image.setAttribute('src', src);
  addToAFrame(image);
  // hide loading display
  vis.fadeOutById('loading-vr')
}

// Insert the image tag into the Aframe components so they detect it
const addToAFrame = (image) => {
  const assets = document.querySelector('a-assets');
  const sky = document.querySelector('a-sky');
  if(assets && sky) assets.appendChild(image);
  sky.setAttribute('src', `#vr-image`);
}

document.addEventListener("turbolinks:load", loadVrAsync);