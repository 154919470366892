import React from "react"
import PropTypes from "prop-types"
import _ from 'lodash';

// const MEDIA_TYPES = [
//   'Image',
//   'Audio',
//   'Video',
// ]

const FilterRecords = (props) => {

  const categoryOptions = props.categories.map(c => {
    return <option key={c.name} value={c.id}>{_.capitalize(c.name)}</option>
  })

  categoryOptions.unshift(<option key='defaultCategory' value={''}>-- Select Category -- </option>)

  const localityOptions = props.localities.map(l => {
    return <option key={l.name} value={l.id}>{l.name}</option>
  })

  localityOptions.unshift(<option key='defaultLocality' value={''}>-- Select Area -- </option>)

  // DEPRECATED - we no longer use Media Type
  // const mediaOptions = MEDIA_TYPES.map(t => {
  //   return <option key={t} value={t}>{t}</option>
  // })

  // mediaOptions.unshift(<option key='defaultType' value={''}>-- Select Type -- </option>)

  if(!props.showingFilter) return null;

  return (

    <form
      onSubmit={props.handleSubmit}
      className={'smb'}
    >

      {props.categories.length > 0 &&
        <select
          onChange={props.handleChange}
          value={props.values.category_id}
          name="category_id"
          id="category_id_select"
          className="xsmb"
        >
          {categoryOptions}
        </select>
      }

      {props.localities.length > 0 &&
        <select
          onChange={props.handleChange}
          value={props.values.locality_id}
          name="locality_id"
          id="locality_id_select"
          className="xsmb"
        >
          {localityOptions}
        </select>
      }

      {/* DEPRECATED - we no longer use media_type */}
      {/* <select
        onChange={props.handleChange}
        value={props.values.media_type}
        name="media_type"
        id="media_type_select"
        className="xsmb"
      >
        {mediaOptions}
      </select> */}

      <input
        value={props.values.search}
        name="search"
        id="search_field"
        type="text"
        className="smb"
        onChange={props.handleChange}
        placeholder='Search...'
      />

      <input
        type="submit"
        value="Go"
        className="button cream-border expanded sp"
      />

    </form>
  );
}

export default FilterRecords

FilterRecords.propTypes = {
  categories: PropTypes.array,
  localities: PropTypes.array,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
}
